'use client';
import { AlertContent } from 'components/common/Alert/AlertContent';
import { PanelBasic } from 'components/layout/Panel/PanelBasic';
import { makeStyles, VariantStyleProps } from 'lib/makeStyles';
import React, { ReactNode, useState } from 'react';
import { useBorderVariantStyles } from 'styles/variantStyles';

import { CloseButton } from '../Button';
import { PopoverOpenTransition } from '../PopoverForm/PopoverOpenTransition';
import { AlertAccentGrid } from './AlertAccentGrid';
import { useAlertGradientVariantStyles } from './alertVariantStyles';

export type AlertTypes = 'success' | 'danger' | 'info';

export type AlertType = {
  intent?: AlertTypes;
  message?: string | ReactNode;
  description?: string;
  fullWidth?: boolean;
  isSmall?: boolean;
  enableHover?: boolean;
  action?: GenericHandler;
  actionLabel?: string;
  secondaryAction?: GenericHandler;
  secondaryActionLabel?: string;
  showSecondaryCancel?: boolean;
  inlineAction?: GenericHandler;
  inlineActionLabel?: string;
  hasCloseButton?: boolean;
  onClose?: GenericHandler;
  show?: boolean;
} & VariantStyleProps;

export const useAlertStyles = makeStyles({
  root: ({ fullWidth, isSmall, variantStyles, backgroundStyles }: AlertType) => [
    'relative',
    'flex',
    'justify-between',
    'overflow-hidden',
    'border-2 border-white',
    {
      'sm:min-w-[280px]': !fullWidth && !isSmall,
      'w-full': fullWidth && !isSmall,
      'max-w-max': !fullWidth && isSmall
    },
    variantStyles,
    backgroundStyles
  ],
  closeButton: ['pt-3 px-2']
});

export const Alert: React.FC<AlertType> = ({
  hasCloseButton = true,
  showSecondaryCancel = false,
  actionLabel = 'Submit',
  show = true,
  fullWidth = false,
  isSmall = false,
  enableHover = false,
  intent = 'danger',
  ...restProps
}: AlertType) => {
  const props = {
    intent,
    hasCloseButton,
    showSecondaryCancel,
    actionLabel,
    show,
    fullWidth,
    isSmall,
    enableHover,
    ...restProps
  };
  const [visible, setVisible] = useState<boolean>(show);

  const backgroundStyles = useAlertGradientVariantStyles({ enableHover })({
    intent,
    interaction: 'hover',
    mode: 'default',
    prominence: 'loud'
  });

  const borderStyles = useBorderVariantStyles({ enableHover })({
    intent,
    interaction: 'hover',
    mode: 'default',
    prominence: 'default'
  });

  const alertStyles = useAlertStyles({
    intent,
    backgroundStyles,
    borderStyles,
    ...props
  });

  return (
    <PopoverOpenTransition open={visible}>
      <PanelBasic
        type="popover"
        className={alertStyles.root}
        defaultPadding={false}
        disableBorder
        fullWidth={fullWidth}>
        <AlertAccentGrid intent={intent} />
        <AlertContent {...props} />
        {hasCloseButton && (
          <CloseButton
            className={alertStyles.closeButton}
            onClose={(e?: GenericEvent) => {
              setVisible(false);
              props.onClose && props.onClose(e);
              return;
            }}
          />
        )}
      </PanelBasic>
    </PopoverOpenTransition>
  );
};

Alert.displayName = 'AlertGlobal';
