'use client';
import { PanelStyleProps } from 'components/layout/Panel/Panel';
import { PanelFlexProps, PanelFlexStyleProps } from 'components/layout/Panel/PanelFlex';
import { PanelItemProps } from 'components/layout/Panel/PanelItem';
import { makeStyles } from 'lib/makeStyles';
import React, { ForwardedRef, forwardRef, useRef } from 'react';
import {
  usePanelFlexStyles,
  usePanelPaddingStyles,
  usePanelRoundedStyles,
  usePanelSpacingStyles
} from 'styles/panelStyles';

export type PanelBasicProps = {
  defaultPadding?: boolean;
  type?: 'popover' | 'card' | 'screen';
  fullWidth?: boolean;
  fullHeight?: boolean;
  disableBorder?: boolean;
  disableBackground?: boolean;
  disableShadow?: boolean;
  disablePanelStyle?: boolean;
  flex?: boolean;
  intent?: 'primary' | 'neutral';
  ref?: ForwardedRef<HTMLDivElement | HTMLFormElement>;
} & ParentClassNameProp &
  ChildrenProp &
  PanelFlexProps &
  Omit<PanelItemProps, 'enableBackground' | 'enableBorder' | 'intent'> &
  Pick<PanelStyleProps, 'roundedStyles'> &
  Pick<PanelFlexStyleProps, 'flexStyles' | 'paddingStyles' | 'spacingStyles'> &
  Pick<JSX.IntrinsicElements['div'], 'style'>;

const useStyles = makeStyles<PanelBasicProps>()({
  root: (props) => [
    {
      'bg-primaryBackground': !props?.disableBackground && props.intent !== 'neutral',
      'bg-neutralBackground': props.intent === 'neutral',
      'border border-primaryBorder': props.type !== 'screen' && !props.disableBorder,
      'shadow-md': props.type === 'popover',
      'shadow-sm': props.type === 'card',
      'w-full': props.fullWidth,
      'h-full': props.fullHeight
    },
    props.flex && props.flexStyles,
    props.spacingStyles,
    props.paddingStyles,
    props.roundedStyles,
    props.className
  ]
});

export const PanelBasic: React.FC<PanelBasicProps> = forwardRef<
  HTMLDivElement | HTMLFormElement,
  PanelBasicProps
>(
  (
    {
      children,
      style,
      defaultPadding = true,
      intent = 'primary',
      type = 'popover',
      fullWidth = false,
      fullHeight = false,
      disableBorder = false,
      disableBackground = false,
      disableShadow = false,
      disablePanelStyle = false,
      rounded = 'MD',
      padding = true,
      paddingY = 'SM',
      flex = false,
      ...restProps
    },
    ref
  ) => {
    const props = {
      defaultPadding,
      intent,
      type,
      fullWidth,
      fullHeight,
      disableBorder,
      disableBackground,
      disableShadow,
      disablePanelStyle,
      rounded,
      padding,
      paddingY,
      flex,
      ...restProps
    };

    const customRef = useRef(null);
    const flexStyles = usePanelFlexStyles(props);
    const spacingStyles = usePanelSpacingStyles(props);
    const paddingStyles = usePanelPaddingStyles(props);
    const roundedStyles = usePanelRoundedStyles(props);

    const styles = useStyles({
      ...props,
      flexStyles: flexStyles.flex,
      spacingStyles: spacingStyles.spacing,
      paddingStyles: paddingStyles.padding,
      roundedStyles: roundedStyles.rounded
    });

    return (
      <div
        ref={ref ?? customRef}
        className={!props.disablePanelStyle ? styles.root : undefined}
        style={style}>
        {children}
      </div>
    );
  }
);

PanelBasic.displayName = 'PanelBasic';
